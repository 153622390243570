import L from '@/utils/leaflet.js'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { leafletCorridor } from '@/utils/leaflet-corridor'

export default {
  props: {
    isGeo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('geozones', {
      isHidingGeo: state => state.isHidingGeo,
      geozones: state => state.geozones,
      hideEditedGeozone: state => state.hideEditedGeozone,
      fitBoundsToGeozone: state => state.fitBoundsToGeozone,
      color: state => state.color,
      width: state => state.width,
      type: state => state.type,
      isDrawing: state => state.isDrawing,
      isEditing: state => state.isEditing,
      geozoneShape: state => state.shape,
      // ИВЕНТЫ
      addGeozoneEvent: state => state.addGeozoneEvent,
      deleteGeozoneEvent: state => state.deleteGeozoneEvent,
      updateGeozoneEvent: state => state.updateGeozoneEvent,
      currentMeasure: state => state.currentMeasure
    }),
    ...mapState('map', {
      isHideAll: state => state.eyeTriggered.value
    }),
    ...mapState('login', {
      isNeedChangePassword: state => state.isNeedChangePassword
    }),
    ...mapGetters('login', ['changingCompany', 'activeCompanyId']),
    ...mapGetters('geozones', ['measure'])
  },

  data() {
    return {
      markerClusterGroup: null,
      // geozones: [],
      visibleLayers: [],
      drawenGeozones: {},
      drawingGeo: null,
      geozoneParams: {
        shape: 'polygon',
        type: 'fillingstation',
        color: '#F56C6C',
        width: '1'
      },
      myRenderer: null,
      geoId: null,
      labelClassNames: {
        Красный: 'geozone-label-red',
        Оранжевый: 'geozone-label-orange',
        Синий: 'geozone-label-blue',
        Зеленый: 'geozone-label-green',
        Серый: 'geozone-label-grey'
      },
      markerColors: {
        '#F56C6C': 'red',
        '#E68A2F': 'orange',
        '#1585D8': 'blue',
        '#1BB934': 'green',
        '#E6EAEE': 'grey'
      },
      geozonesLayer: null,
      gz: {},
      st: '',
      currentLayers: [],
      geozonesReady: false,
      layersWithTooltips: [],
      currentZoom: '',
      corridors: [],
      action: '',
      corridorsArrayHistory: [],
      popStatus: false,
      zoomLvl: 0
    }
  },

  watch: {
    isNeedChangePassword(val) {
      if (!val) {
        this.handleGettingGeozones()
      }
    },
    measure(val) {
      console.log('ssssssssssss')
      // this.currentLayers.forEach(([layer, geozone]) => {
      //   const drawenGeo = this.geozones.find(geo => geo.id === geozone.id)
      //   if (drawenGeo) {
      //     const { type, name, color, area } = drawenGeo
      //     layer.unbindTooltip()
      //     this.bindTooltip(layer, name, color.key, area, type.key)
      //   }
      // })
    },
    changingCompany() {
      this.markerClusterGroup.clearLayers()
      this.helpRemoveGeozoneFromMap()
      this.helpRemoveCorridorsFromMap()
      this.drawenGeozones = {}
      this.corridors = []
    },
    isHidingGeo(val) {
      if (val) {
        this.markerClusterGroup.removeFrom(this.map)

        this.helpRemoveGeozoneFromMap()
      } else {
        this.markerClusterGroup.addTo(this.map)
        this.helpAddCorridorsToMap()
        Object.values(this.drawenGeozones).forEach(geo => geo.addTo(this.map))
      }
    },

    isHideAll(val) {
      if (val === 'all') {
        const geozonesArray = Object.values(this.drawenGeozones)
        geozonesArray.forEach(geo => this.map.removeLayer(geo))
      }
    },

    fitBoundsToGeozone(val) {
      const geozoneId = Object.keys(val)[0]

      if (!this.drawenGeozones[geozoneId] || Object.values(this.drawenGeozones).length === 0) return
      this.drawenGeozones[geozoneId].addTo(this.map)
      switch (this.drawenGeozones[geozoneId].options.shape) {
        case 'polygon':
          this.map.fitBounds(this.drawenGeozones[geozoneId].getBounds(), {
            paddingTopLeft: [300, 300]
          })
          break
        case 'circle':
          this.map.fitBounds(this.drawenGeozones[geozoneId].getBounds(), {
            paddingTopLeft: [300, 300]
          })
          break
        case 'line':
          this.map.fitBounds(this.drawenGeozones[geozoneId].getBounds(), {
            paddingTopLeft: [300, 300]
          })
          break
        case 'point':
          this.map.setView(this.drawenGeozones[geozoneId].getLatLng(), this.map.getZoom())
          break
        default:
          break
      }
    },

    addGeozoneEvent(val) {
      if (Array.isArray(val)) {
        val.forEach(id => {
          this.showGeozones([this.geozones.find(k => k.id === id)])
        })
      } else {
        const geozoneId = Object.keys(val)[0]
        this.showGeozones([this.geozones.find(k => k.id === geozoneId)])
      }
    },

    deleteGeozoneEvent(geozoneIds) {
      this.action = 'delete'
      geozoneIds.forEach(el => {
        if (this.corridors.length > 0) {
          this.corridors.forEach(item => {
            if (el === item.id && this.drawenGeozones[el] && this.drawenGeozones[el].options.shape === 'line') {
              this.map.removeLayer(item.corridor)
            }
          })
        }
        if (this.drawenGeozones[el]) {
          this.map.removeLayer(this.drawenGeozones[el])
          delete this.drawenGeozones[el]
        }
      })
    },

    updateGeozoneEvent(val) {
      this.action = 'update'
      const geozoneId = Object.keys(val)[0]
      if (Object.keys(this.drawenGeozones).length > 0) {
        this.drawenGeozones[geozoneId].removeFrom(this.map)
        this.geoId = null
        this.showGeozones([this.geozones.find(k => k.id === geozoneId)])
        this.map.editTools.stopDrawing()
      }
    },

    geozoneShape(val) {
      const geoColor = Object.keys(val)[0]
      this.geozoneParams.shape = geoColor
      this.geozoneParams.width = 1
      this.drawGeo()
    },

    color(val) {
      const geoShape = Object.keys(val)[0]
      this.geozoneParams.color = geoShape
      if (this.drawingGeo) {
        if (this.drawingGeo.options.shape === 'point') {
          this.drawingGeo.setIcon(
            L.icon({
              iconUrl: `static/markers/${this.geozoneParams.type}_${this.markerColors[geoShape]}.svg`,
              iconSize: [26, 36],
              iconAnchor: [13, 36]
            })
          )
        } else {
          this.drawingGeo.setStyle({ color: geoShape })
        }
      } else if (this.drawenGeozones[this.geoId].options.shape === 'point') {
        this.drawenGeozones[this.geoId].setIcon(
          L.icon({
            iconUrl: `static/markers/${this.geozoneParams.type}_${this.markerColors[geoShape]}.svg`,
            iconSize: [26, 36],
            iconAnchor: [13, 36]
          })
        )
      } else {
        this.drawenGeozones[this.geoId].setStyle({ color: geoShape })
      }
    },

    type(val) {
      const geoType = Object.keys(val)[0]
      this.geozoneParams.type = geoType
      if (this.drawingGeo) {
        this.drawingGeo.options.shape === 'point' &&
          this.drawingGeo.setIcon(
            L.icon({
              iconUrl: `static/markers/${geoType}_${this.markerColors[this.geozoneParams.color]}.svg`,
              iconSize: [26, 36],
              iconAnchor: [13, 36]
            })
          )
      } else {
        this.drawenGeozones[this.geoId].options.shape === 'point' &&
          this.drawenGeozones[this.geoId].setIcon(
            L.icon({
              iconUrl: `static/markers/${geoType}_${this.markerColors[this.geozoneParams.color]}.svg`,
              iconSize: [26, 36],
              iconAnchor: [13, 36]
            })
          )
      }
    },

    width(val) {
      const geoWidth = Object.keys(val)[0]
      this.geozoneParams.width = geoWidth
      // если раскоментить будет доступна функция рисования еще одной геозоны (когда меняем ширину у линии)
      // this.drawGeo()
    },

    isDrawing(val) {
      if (val) {
        this.geozoneParams = {
          shape: 'polygon',
          type: 'fillingstation',
          color: '#F56C6C',
          width: '1'
        }
        this.drawGeo()
      } else if (this.drawingGeo) {
        this.drawingGeo.removeFrom(this.map)
        this.drawingGeo = null
        this.map.editTools.stopDrawing()
      }
    },

    isEditing(val) {
      console.log('🚀 ~ isEditing ~ val:', val)
      const geozoneId = Object.keys(val)[0]
      const isEditingGeozone = Object.values(val)[0]
      this.corridors.forEach(el => {
        if (el.id === geozoneId) {
          this.map.removeLayer(el.corridor)
        }
      })
      if (isEditingGeozone) {
        this.editGeo(geozoneId)
      } else {
        this.drawGeozoneBack(geozoneId)

        if (this.isHidingGeo) {
          this.drawenGeozones[geozoneId].removeFrom(this.markerClusterGroup)
          if (this.corridors.length > 0) {
            this.corridors.forEach(item => {
              this.map.removeLayer(item.corridor)
            })
          }
        }
      }
    },

    geozonesReady(val) {
      console.log('🚀 ~ geozonesReady ~ val:', val)
    }
  },

  created() {
    if (this.isNeedChangePassword) return
    const isGeoHidden = localStorage.getItem('hidegeozones') === 'true'
    this.$store.commit('geozones/TOGGLE_GEOZONES', isGeoHidden)
    this.initMarkerClusterGroup()

    this.handleGettingGeozones()
  },
  methods: {
    initMarkerClusterGroup() {
      this.markerClusterGroup = L.markerClusterGroup({
        showCoverageOnHover: true,
        animate: true,
        chunkedLoading: true,
        chunkDelay: 50,
        iconCreateFunction: this.createClusterIcon
      })
    },

    createClusterIcon(cluster) {
      return L.divIcon({ html: `<div class="geozones_cluster"> ${cluster.getChildCount()}</div>` })
    },

    handleGettingGeozones() {
      this.$store
        .dispatch('geozones/GET_GEOZONES')
        .then(response => {
          this.geozones = response

          this.$nextTick(() => {
            this.zoomLvl = this.map.getZoom()
            if (!this.isHidingGeo) {
              this.map.addLayer(this.markerClusterGroup)
            }

            this.popStatus = this.zoomLvl > 11
            this.showGeozones(this.geozones)
            this.geozonesReady = true
          })
        })
        .catch(error => {
          console.error('Error fetching geozones:', error)
        })
    },
    helpRemoveGeozoneFromMap() {
      const geozonesArray = Object.values(this.drawenGeozones)
      geozonesArray.forEach(geo => this.map.removeLayer(geo))
    },
    helpRemoveCorridorsFromMap() {
      if (this.corridors.length > 0) {
        this.corridors.forEach(item => {
          this.map.removeLayer(item.corridor)
        })
      }
    },
    helpAddCorridorsToMap() {
      if (this.corridors.length > 0) {
        this.corridors.forEach(item => {
          this.map.addLayer(item.corridor)
        })
      }
    },
    _swapTooltip(polygon, geozone) {
      const currentZoom = this.map.getZoom()
      const bounds = this.map.getBounds()
      const component = this
      if (currentZoom >= 13) {
        this.currentLayers.forEach(layer => {
          const type = layer[1].type.key
          layer[0].closeTooltip()
          if (bounds.contains(type === 'polygon' ? layer[0].getLatLngs() : type === 'line' ? layer[0].getLatLngs() : layer[0].getLatLng())) {
            layer[0].bindTooltip(`${layer[1].name} (${Math.round(layer[1].area / 10000)} Га )`, {
              pane: 'geozonenamePane',
              className: component.labelClassNames[layer[1].color.value],
              direction: 'center',
              permanent: true
              // permanent: currentZoom >= 13,
            })
            const found = this.layersWithTooltips.some(shape => shape[1].id === layer[1].id)
            if (!found) this.layersWithTooltips.push(layer)
          }
        })
      } else {
        this.layersWithTooltips.forEach(shape => {
          shape[0].closeTooltip()
        })
      }
    },

    getGeozones() {
      const currentZoom = this.map.getZoom()

      this.popStatus = false
      if (currentZoom > 11) {
        this.popStatus = true
      }
      this.drawenGeozones = []
      const geozonesArray = Object.values(this.drawenGeozones)
      geozonesArray.forEach(geo => geo.removeFrom(this.map))

      this.corridorsArrayHistory.forEach(corridor => {
        this.map.removeLayer(corridor)
      })

      this.corridorsArrayHistory = []

      this.$store.dispatch('geozones/GET_GEOZONES').then(geoArray => {
        geoArray.forEach(geozone => {
          if (geozone.geometry && geozone.geometry.length !== 0) {
            switch (geozone.type.key) {
              case 'polygon':
                this.drawPolygon(geozone)

                break
              case 'circle':
                this.drawCircle(geozone)

                break
              case 'line':
                this.drawLine(geozone)

                const options = {
                  corridor: geozone.width,
                  className: 'route-corridor',
                  color: geozone.color.key,
                  opacity: 0.3
                }

                const corridor = leafletCorridor(geozone.geometry, options)

                this.corridorsArrayHistory.push(corridor)

                break
              case 'point':
                this.drawMarker(geozone)

                break
              default:
                break
            }
          }
        })
      })
      this.geozonesReady = true
    },

    drawGeozoneBack(id) {
      const geozone = this.drawenGeozones[id]
      if (geozone) {
        geozone.removeFrom(this.map)
        this.markerClusterGroup.removeLayer(geozone)
        geozone.off('editable:vertex:dragend')
        geozone.off('editable:dragend')
      }
      this.geoId = null
      this.showGeozones([this.geozones.find(k => k.id === id)])
    },

    drawGeo() {
      const { color, shape, type, width } = this.geozoneParams
      this.drawingGeo && this.drawingGeo.removeFrom(this.map) && this.drawingGeo.off('editable:drawing:clicked') && this.drawingGeo.off('editable:vertex:dragend') && this.drawingGeo.off('editable:dragend')
      const _ = undefined
      switch (shape) {
        case 'polygon':
          this.drawingGeo = this.map.editTools
            .startPolygon(_, {
              color,
              weight: 1,
              shape: 'polygon',
              type
            })
            .on('editable:drawing:clicked', e => {
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: e.layer.getLatLngs()[0].map(coords => [coords.lat, coords.lng])
              })
            })
            .on('editable:vertex:dragend', e => {
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: e.layer.getLatLngs()[0].map(coords => [coords.lat, coords.lng])
              })
            })
          break
        case 'circle':
          this.drawingGeo = this.map.editTools
            .startCircle(_, {
              color,
              weight: 1,
              shape: 'circle',
              type
            })
            .on('editable:vertex:dragend', e => {
              const coords = e.layer.getLatLng()
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: [coords.lat, coords.lng],
                radius: e.layer.getRadius()
              })
            })
          break
        case 'line':
          this.drawingGeo = this.map.editTools
            .startPolyline(_, {
              color,
              weight: 1,
              shape: 'line',
              type
            })
            .on('editable:drawing:clicked', e => {
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: e.layer.getLatLngs().map(coords => [coords.lat, coords.lng])
              })
            })
            .on('editable:vertex:dragend', e => {
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: e.layer.getLatLngs().map(coords => [coords.lat, coords.lng])
              })
            })
          break
        case 'point':
          this.drawingGeo = this.map.editTools
            .startMarker(_, {
              icon: L.icon({
                iconUrl: `static/markers/${type}_${this.markerColors[color]}.svg`,
                iconSize: [26, 36],
                iconAnchor: [13, 36]
              }),
              shape: 'point',
              type,
              color
            })
            .on('editable:drawing:clicked', e => {
              const coords = e.layer.getLatLng()
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: [coords.lat, coords.lng]
              })
            })
            .on('editable:dragend', e => {
              const coords = e.layer.getLatLng()
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: [coords.lat, coords.lng]
              })
            })
          break
        default:
          break
      }
    },

    showGeozones(geozonesToDraw) {
      geozonesToDraw.forEach(geozone => {
        if (this.map.hasLayer(geozone) || this.markerClusterGroup.hasLayer(geozone)) return

        if (geozone.geometry && geozone.geometry.length !== 0) {
          const { type } = geozone

          switch (type.key) {
            case 'polygon':
              this.drawPolygon(geozone)
              break
            case 'circle':
              this.drawCircle(geozone)
              break
            case 'line':
              this.drawLine(geozone)
              break
            case 'point':
              this.drawMarker(geozone)
              break
            default:
              break
          }
        }
      })
    },

    drawPolygon(geozone) {
      const { geometry, color, name, area, id, is_never_cluster } = geozone
      const polygonOptions = {
        color: color.key,
        opacity: 0.3,
        type: color.icon,
        renderer: this.myRenderer,
        permanent: !(this.zoomLvl > 11)
      }

      const polygon = new L.PolygonClusterable(geometry, polygonOptions)

      this.bindTooltip(polygon, name, color.key, area, 'polygon')
      if (!this.isHidingGeo) {
        if (is_never_cluster) {
          polygon.addTo(this.map)
        } else {
          this.markerClusterGroup.addLayer(polygon)
        }
      }

      polygon.options.shape = 'polygon'
      this.drawenGeozones[id] = polygon
      this.currentLayers.push([polygon, geozone])
    },

    drawCircle(geozone) {
      const { geometry, color, name, area, id, width } = geozone
      const circleOptions = {
        radius: width,
        color: color.key,
        weight: 1,
        type: color.icon,
        renderer: this.myRenderer,
        permanent: !(this.zoomLvl > 11)
      }

      const circle = L.circle(geometry[0], circleOptions)

      this.bindTooltip(circle, name, color.key, area, 'circle')

      if (geozone.is_never_cluster) {
        this.map.addLayer(circle)
      } else {
        this.markerClusterGroup.addLayer(circle)
      }

      circle.options.shape = 'circle'
      this.drawenGeozones[id] = circle
      this.currentLayers.push([circle, geozone])
    },
    editGeo(id) {
      this.geoId = id
      this.geozoneParams.type = this.drawenGeozones[id].options.type
      this.geozoneParams.color = this.drawenGeozones[id].options.color
      if (this.markerClusterGroup.hasLayer(this.drawenGeozones[id])) {
        this.drawenGeozones[id].removeFrom(this.markerClusterGroup)
        this.drawenGeozones[id].addTo(this.map)
      }

      this.drawenGeozones[id].enableEdit()

      if (this.drawenGeozones[id].options.shape === 'point') {
        this.drawenGeozones[id].on('editable:dragend', e => {
          const coords = e.layer.getLatLng()
          const updatedMarker = L.marker(coords, {
            ...this.drawenGeozones[id].options
          })
          this.map.removeLayer(this.drawenGeozones[id])
          this.drawenGeozones[id] = updatedMarker
          updatedMarker.addTo(this.map)

          this.$store.commit('geozones/SET_GEOZONE_COORDS', {
            latlng: [coords.lat, coords.lng]
          })
        })
      } else {
        this.drawenGeozones[id].on('editable:vertex:dragend editable:vertex:deleted', e => {
          switch (e.layer.options.shape) {
            case 'polygon':
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: e.layer.getLatLngs()[0].map(coords => [coords.lat, coords.lng])
              })

              break
            case 'circle':
              const coords = e.layer.getLatLng()
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: [coords.lat, coords.lng],
                radius: e.layer.getRadius()
              })
              break
            case 'line':
              this.$store.commit('geozones/SET_GEOZONE_COORDS', {
                latlng: e.layer.getLatLngs().map(coords => [coords.lat, coords.lng])
              })
              break
            default:
              break
          }
        })
      }
    },
    drawMarker(geozone) {
      const { geometry, color, name, id, is_never_cluster } = geozone
      const markerOptions = {
        icon: L.icon({
          iconUrl: `/static/markers/${geozone.icon.key}_${this.markerColors[geozone.color.key]}.svg`,
          iconSize: [26, 36],
          iconAnchor: [13, 36]
        }),
        type: geozone.icon.key,
        color: geozone.color.key,
        is_never_cluster
      }

      const marker = L.marker(geometry[0], markerOptions)
      this.bindTooltip(marker, name, color.key, null, 'marker')

      if (!this.isHidingGeo) {
        if (is_never_cluster) {
          marker.addTo(this.map)
        } else {
          this.markerClusterGroup.addLayer(marker)
        }
      }

      marker.options.shape = 'point'
      this.drawenGeozones[id] = marker
      this.currentLayers.push([marker, geozone])
    },
    drawLine(geozone) {
      const { geometry, color, name, id, area, width } = geozone
      const lineOptions = {
        color: color.key,
        weight: 1,
        type: color.type.key,
        renderer: this.myRenderer,
        permanent: true
      }
      const line = new L.PolylineClusterable(geometry, lineOptions)
      this.storeLineAndAddToMap(line, geozone, id)
      const corridor = this.createCorridorLayer(geometry, color, name, id, width)

      this.bindTooltip(corridor, name, color.key, area, 'line')
      this.addCorridorToMap(corridor)
      this.action = ''
    },

    storeLineAndAddToMap(line, geozone, id) {
      line.options.shape = 'line'
      this.drawenGeozones[id] = line
      this.currentLayers.push([line, geozone])
      if (!this.isHidingGeo) {
        this.markerClusterGroup.addLayer(line)
      }
    },
    createCorridorLayer(geometry, color, name, id, width) {
      const corridorOptions = {
        corridor: width,
        className: 'route-corridor',
        color: color.key,
        opacity: 0.3,
        weight: 1
      }
      const corridor = leafletCorridor(geometry, corridorOptions)
      this.bindTooltip(corridor, name, color.key)
      if (this.action === 'update') {
        this.removeOldCorridor(id, corridor)
      }
      this.corridors.push({ id, corridor })
      return corridor
    },
    addCorridorToMap(corridor) {
      if (!this.isHidingGeo) {
        this.map.addLayer(corridor)
      }
    },

    bindTooltip(element, name, colorValue, area, type) {
      element.unbindTooltip()

      let tooltipContent = name

      const measureUnit = this.measure || localStorage.getItem('measureGeo') || 'm'
      let areaText = ''
      if (area) {
        switch (measureUnit) {
          case 'm':
            areaText = `${Math.round(area.toFixed(2))} ${this.$t('geozones.meter')}²`
            break
          case 'km':
            areaText = `${(area.toFixed(2) / 1000000).toFixed(2)} ${this.$t('geozones.km')}²`
            break
          case 'he':
            areaText = `${Math.round(area.toFixed(2) / 10000)} ${this.$t('geozones.hectar')}`
            break
        }
        tooltipContent += ` - (${areaText})`
      }

      const colorTranslations = {
        '#E68A2F': 'orange',
        '#F56C6C': 'red',
        '#1585D8': 'blue',
        '#1BB934': 'green',
        '#E6EAEE': 'grey'
      }

      const translatedColorValue = colorTranslations[colorValue]
      const className = this.$t(`geozones.${translatedColorValue}`)
      element.bindTooltip(tooltipContent, {
        pane: 'geozonenamePane',
        className: this.labelClassNames[className],
        direction: 'bottom',
        // пока убираем надписи
        permanent: false
      })
    },
    removeOldCorridor(id, corridor) {
      this.corridors.forEach((el, index) => {
        if (el.id === id) {
          this.map.removeLayer(el.corridor)
          this.corridors.splice(index, 1)
        }
      })
    }
  }
}
